import React, { useEffect, useState } from 'react';
import { Layout, Card, Row, Col } from 'antd';
import { CarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { io } from 'socket.io-client';

const { Header, Footer, Content } = Layout;

const ParkingDashboard = () => {
  const [totalCars, setTotalCars] = useState(0);
  const [averageTime, setAverageTime] = useState(0);
  const [carQueue, setCarQueue] = useState([]);
  const [timeSeriesData, setTimeSeriesData] = useState([]);

  // Drive-through data states
  const [driveThroughCars, setDriveThroughCars] = useState([]);
  const [orderingCars, setOrderingCars] = useState([]);
  const [collectingCars, setCollectingCars] = useState([]);

  useEffect(() => {
    const socket = io('http://mqtt-parser.netsec-coe.com:5000');

    socket.on('connect', () => {
      console.log('Connected to WebSocket');
    });

    // Listen for parking data
    socket.on('parking_data', (data) => {
      setTotalCars(data.total_cars);
      setAverageTime(data.average_time);
    });

    // Listen for car time series data
    socket.on('car_timeseries', (data) => {
      setTimeSeriesData(data.map(car => ({
        time: new Date(car.timestamp * 1000).toLocaleTimeString(),
        timeSpent: car.time_spent
      })));
    });

    // Listen for car queue updates (parking)
    socket.on('car_queue', (queue) => {
      setCarQueue(queue);
    });

    // Listen for drive-through data
    socket.on('drive_through_data', (data) => {
      setDriveThroughCars(data.drive_through_cars);

      // Separate cars based on status
      setOrderingCars(data.drive_through_cars.filter(car => car.status === 'ordering'));
      setCollectingCars(data.drive_through_cars.filter(car => car.status === 'collecting'));
    });

    socket.on('disconnect', () => {
      console.log('WebSocket disconnected');
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ backgroundColor: '#001529', color: 'white', textAlign: 'center', fontSize: '24px' }}>
        Parking and Drive-Through Management Dashboard
      </Header>
      <Content style={{ padding: '50px' }}>
        <Row gutter={[16, 16]}>
          {/* Cars Passed */}
          <Col span={8}>
            <Card title={<span><CarOutlined /> Total Cars Passed</span>} bordered={false} style={{ textAlign: 'center' }}>
              <p style={{ fontSize: '40px' }}>{totalCars}</p>
            </Card>
          </Col>
          {/* Average Time */}
          <Col span={8}>
            <Card title={<span><ClockCircleOutlined /> Average Time Each Car Stayed</span>} bordered={false} style={{ textAlign: 'center' }}>
              <p style={{ fontSize: '40px' }}>{averageTime} sec</p>
            </Card>
          </Col>
          {/* Cars in Queue */}
          <Col span={8}>
            <Card title={<span><CarOutlined /> Cars in Queue</span>} bordered={false} style={{ textAlign: 'center' }}>
              <Row gutter={[8, 8]}>
                {carQueue.map((car, index) => (
                  <Col key={index} span={24}>
                    <Card size="small" style={{ textAlign: 'center' }}>
                      <p>Car ID: {car.object_id}</p>
                      <p>Entered: {new Date(car.ts_entered).toLocaleTimeString()}</p>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Card>
          </Col>
        </Row>

        {/* Time Spent for Each Car as Time Series */}
        <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
          <Col span={24}>
            <Card title="Time Spent by Each Car (Time Series)" bordered={false}>
              <ResponsiveContainer width="100%" height={400}>
                <LineChart
                  data={timeSeriesData}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="time" />
                  <YAxis label={{ value: 'Time Spent (ms)', angle: -90, position: 'insideLeft' }} />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="timeSpent" stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineChart>
              </ResponsiveContainer>
            </Card>
          </Col>
        </Row>

        {/* Drive-Through Stations */}
        <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
          <Col span={12}>
            <Card title="Ordering Station" bordered={false} style={{ textAlign: 'center' }}>
              {orderingCars.map(car => (
                <Card key={car.id} size="small" style={{ marginBottom: '10px' }}>
                  <p>Car ID: {car.id}</p>
                  <p>Time Spent: {car.time_spent}s</p>
                </Card>
              ))}
            </Card>
          </Col>
          <Col span={12}>
            <Card title="Collecting Station" bordered={false} style={{ textAlign: 'center' }}>
              {collectingCars.map(car => (
                <Card key={car.id} size="small" style={{ marginBottom: '10px' }}>
                  <p>Car ID: {car.id}</p>
                  <p>Time Spent: {car.time_spent}s</p>
                </Card>
              ))}
            </Card>
          </Col>
        </Row>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Parking and Drive-Through Management ©2024</Footer>
    </Layout>
  );
};

export default ParkingDashboard;
