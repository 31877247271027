import React, { useState, useEffect } from 'react';
import { Layout, Card } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { io } from 'socket.io-client';  // Import socket.io-client
import './DriveThroughDashboard.css'; // Custom CSS for aerial view

const { Header, Footer, Content } = Layout;

const DriveThroughDashboard = () => {
    const [cars, setCars] = useState([]);
    const [avgOrderingTime, setAvgOrderingTime] = useState(0);
    const [avgCollectingTime, setAvgCollectingTime] = useState(0);
    const [totalCarsPassed, setTotalCarsPassed] = useState(0);  // New state for total cars passed
    const [orderingTimeSeries, setOrderingTimeSeries] = useState([]);
    const [collectingTimeSeries, setCollectingTimeSeries] = useState([]);

    // Establish WebSocket connection to listen for drive_through_data
    useEffect(() => {
        const socket = io('http://mqtt-parser.netsec-coe.com:5000');  // Your WebSocket URL

        // Listen for 'drive_through_data' event
        socket.on('drive_through_data', (data) => {
            console.log('Drive-through data received:', data);
            const carsData = data.drive_through_cars;

            // Update state with the cars in the drive-through
            setCars(carsData);

            // Update the average times when cars leave their respective stations
            setAvgOrderingTime(data.average_ordering_time);
            setAvgCollectingTime(data.average_collecting_time);

            // Update total cars passed
            setTotalCarsPassed(data.total_cars_passed);

            // Update the time series for the graphs
            setOrderingTimeSeries(prev => [...prev, { time: new Date().toLocaleTimeString(), timeSpent: data.average_ordering_time }]);
            setCollectingTimeSeries(prev => [...prev, { time: new Date().toLocaleTimeString(), timeSpent: data.average_collecting_time }]);
        });

        // Clean up the WebSocket connection on component unmount
        return () => {
            socket.disconnect();
        };
    }, []);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header style={{ backgroundColor: '#001529', color: 'white', textAlign: 'center', fontSize: '24px' }}>
                Retail Location Drive-Through Map
            </Header>

            {/* Aerial View Map Layout */}
            <Content style={{ padding: '50px', textAlign: 'center' }}>
                <div className="drive-through-map">
                    {/* Retail Location Block */}
                    <div className="shop">
                        <Card className="retail-card" title="Retail Location">
                            <p>Main Building</p>
                            <div className="total-cars">
                                <h2>Total Cars Passed: {totalCarsPassed}</h2>  {/* Display total cars passed */}
                            </div>
                        </Card>

                        {/* Ordering Station */}
                        <div className="station ordering">
                            <Card title={`Ordering (Avg: ${avgOrderingTime.toFixed(2)} sec)`}>
                                <div className="station-content">
                                    {cars.filter(car => car.status === 'ordering').map(car => (
                                        <p key={car.id} style={{ color: 'black' }}>Car {car.id} (Time: {car.time_spent}s)</p>
                                    ))}
                                </div>
                            </Card>
                        </div>

                        {/* Collection Station */}
                        <div className="station collecting">
                            <Card title={`Collection (Avg: ${avgCollectingTime.toFixed(2)} sec)`}>
                                <div className="station-content">
                                    {cars.filter(car => car.status === 'collecting').map(car => (
                                        <p key={car.id} style={{ color: 'black' }}>Car {car.id} (Time: {car.time_spent}s)</p>
                                    ))}
                                </div>
                            </Card>
                        </div>
                    </div>

                    {/* Time Series Graphs */}
                    <div className="time-series-graphs">
                        <h3>Ordering Time Series</h3>
                        <ResponsiveContainer width="50%" height={300}>
                            <LineChart data={orderingTimeSeries}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="time" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="timeSpent" stroke="#8884d8" />
                            </LineChart>
                        </ResponsiveContainer>

                        <h3>Collecting Time Series</h3>
                        <ResponsiveContainer width="50%" height={300}>
                            <LineChart data={collectingTimeSeries}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="time" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="timeSpent" stroke="#82ca9d" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </Content>

            <Footer style={{ textAlign: 'center' }}>Drive-Through Management ©2024</Footer>
        </Layout>
    );
};

export default DriveThroughDashboard;
