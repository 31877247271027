import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Layout, Menu } from 'antd'; // Import Ant Design Layout and Menu components
import ParkingDashboard from './components/ParkingDashboard'; // Your existing parking component
import DriveThroughDashboard from './components/DriveThroughDashboard'; // New drive-through component

const { Header, Content, Footer } = Layout;

const App = () => {
  return (
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        {/* Header with Navigation Menu */}
        <Header>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['1']}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Menu.Item key="1">
              <Link to="/parking">Parking</Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/drive-through">Drive-Through</Link>
            </Menu.Item>
          </Menu>
        </Header>

        {/* Main Content for each case */}
        <Content style={{ padding: '50px' }}>
          <Routes>
            <Route path="/parking" element={<ParkingDashboard />} />
            <Route path="/drive-through" element={<DriveThroughDashboard />} />
            <Route path="/" element={<h1>Welcome! Please select a use case</h1>} />
          </Routes>
        </Content>

        {/* Footer */}
        <Footer style={{ textAlign: 'center' }}>Parking & Drive-Through Management ©2024</Footer>
      </Layout>
    </Router>
  );
};

export default App;
